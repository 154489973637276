import "../../css/adminSignalTest.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { TerminalFrame } from "../../components/terminalFrame";
import {
  reduxInit,
  openTerminal,
  setTerminalUser,
  setTerminalTag,
  setTerminalDeviceMsg,
  setTerminalDeviceMsg2,
} from "../../store/actions/navigation";
import { selectTerminal } from "../../store/selectors/navigation";
import {
  getTerminalDevices,
  getTerminalSupportedDevices,
  getAuth,
  getAuth2,
  clearTerminalSessionID,
} from "../../store/actions/admin";

import { selectTerminalDevices } from "../../store/selectors/admin";

function AdminSubAdmin1() {
  const terminal = useSelector(selectTerminal);
  const termDevices = useSelector(selectTerminalDevices);
  useEffect(() => {
    dispatch(reduxInit());
    dispatch(getTerminalDevices());
    dispatch(getTerminalSupportedDevices()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (termDevices.sessionId.length > 0) {
      const windowFeatures = `left=${window.screen.width / 2 - 400},top=${
        window.screen.height / 2 - 300
      },width=800,height=600`;
      window.open(
        `https://hub.geuzenet.nl/ssh?port=${
          termDevices.devices.type4GModem.includes(terminal.tag)
            ? `4${terminal.tag.slice(4)}`
            : `1${terminal.tag.slice(4)}`
        }&user=${terminal.user}&session_id=${termDevices.sessionId}`,
        `Terminal - ${terminal.tag} - ${termDevices.sessionId}`,
        windowFeatures
      );
      setTimeout(function () {
        dispatch(clearTerminalSessionID());
      }, 1000);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termDevices.sessionId]);
  const dispatch = useDispatch();
  console.log(termDevices);
  return Object.keys(termDevices.devices).length > 0 &&
    termDevices.supportedDevices.length > 0 ? (
    <div>
      <div className="inputArea">
        <div className="infoLine">
          <div className="infoLineKey">{"Device tag: "}</div>
          <div className="infoLineValue">
            <input
              className="tagInputField"
              disabled={termDevices.sessionId.length > 0}
              value={terminal.tag}
              onChange={(e) => {
                dispatch(
                  setTerminalTag(
                    e.target.value.replace(/\s+/g, "").toUpperCase()
                  )
                );
                if (
                  e.target.value.replace(/\s+/g, "").toUpperCase().length < 8 ||
                  e.target.value
                    .replace(/\s+/g, "")
                    .toUpperCase()
                    .slice(0, 3) !== "GZN"
                ) {
                  dispatch(
                    setTerminalDeviceMsg("Expected tag format : GZNXXXXX")
                  );
                  dispatch(setTerminalDeviceMsg2(""));
                } else if (
                  !termDevices.devices.allDevices.includes(
                    e.target.value.replace(/\s+/g, "").toUpperCase()
                  )
                ) {
                  dispatch(
                    setTerminalDeviceMsg(
                      `Tag ${e.target.value
                        .replace(/\s+/g, "")
                        .toUpperCase()} does not exist`
                    )
                  );
                  dispatch(setTerminalDeviceMsg2(""));
                } else if (
                  !termDevices.supportedDevices
                    .map((device) => device.tag)
                    .includes(e.target.value.replace(/\s+/g, "").toUpperCase())
                ) {
                  dispatch(
                    setTerminalDeviceMsg(
                      `${e.target.value
                        .replace(/\s+/g, "")
                        .toUpperCase()} does not support terminal access`
                    )
                  );
                  dispatch(setTerminalDeviceMsg2(""));
                } else {
                  dispatch(
                    setTerminalDeviceMsg(
                      termDevices.supportedDevices.find(
                        (device) =>
                          device.tag ===
                          e.target.value.replace(/\s+/g, "").toUpperCase()
                      ).location
                    )
                  );
                  dispatch(
                    setTerminalDeviceMsg2(
                      `${
                        termDevices.supportedDevices.find(
                          (device) =>
                            device.tag ===
                            e.target.value.replace(/\s+/g, "").toUpperCase()
                        ).type
                      } - ${
                        termDevices.supportedDevices.find(
                          (device) =>
                            device.tag ===
                            e.target.value.replace(/\s+/g, "").toUpperCase()
                        ).model
                      }`
                    )
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="infoLine"></div>
        <div className="infoLine">
          <div className="infoLineKey">{"User: "}</div>
          <div className="infoLineValue">
            <input
              className="tagInputField"
              disabled={termDevices.sessionId.length > 0}
              value={terminal.user}
              onChange={(e) => {
                dispatch(setTerminalUser(e.target.value.replace(/\s+/g, "")));
              }}
            />
          </div>
        </div>
        <div className="infoLine"></div>
        <div className="infoLine">{terminal.terminalDeviceMsg}</div>
        <div className="infoLine">{terminal.terminalDeviceMsg2}</div>
        <div className="infoLine"></div>
      </div>
      <button
        className={
          !terminal.open &&
          terminal.user.replace(/\s+/g, "").length > 0 &&
          termDevices.devices.allDevices.includes(terminal.tag) &&
          termDevices.supportedDevices
            .map((device) => device.tag)
            .includes(terminal.tag) &&
          !termDevices.sessionId.length > 0
            ? "serviceUsageButton"
            : "sdbInactive"
        }
        onClick={(e) => {
          if (
            terminal.user.replace(/\s+/g, "").length > 0 &&
            termDevices.devices.allDevices.includes(terminal.tag) &&
            termDevices.supportedDevices
              .map((device) => device.tag)
              .includes(terminal.tag) &&
            !termDevices.sessionId.length > 0
          ) {
            // dispatch(getAuth());
            dispatch(getAuth2());
            // setTimeout(function () {
            //   // dispatch(openTerminal());
            //   window.open(
            //     `https://hub.geuzenet.nl/ssh_test?port=${
            //       termDevices.devices.type4GModem.includes(terminal.tag)
            //         ? `4${terminal.tag.slice(4)}`
            //         : `1${terminal.tag.slice(4)}`
            //     }&user=${terminal.user}&session_id=${termDevices.sessionId}`,
            //     `Terminal - ${terminal.tag}`,
            //     windowFeatures
            //   );
            // }, 3000);
            // setTimeout(function () {
            //   dispatch(clearTerminalSessionID());
            // }, 4000);
            // dispatch(openTerminal());
          }
        }}
      >
        Connect
      </button>
      {terminal.open ? (
        <TerminalFrame
          url={`https://hub.geuzenet.nl/ssh?port=${
            termDevices.devices.type4GModem.includes(terminal.tag)
              ? `4${terminal.tag.slice(4)}`
              : `1${terminal.tag.slice(4)}`
          }&user=${terminal.user}`}
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    <div></div>
  );
}
export default AdminSubAdmin1;
