import axios from "axios";
const key = process.env.REACT_APP_API_KEY;

export const adminInit = () => {
  return {
    type: "admin/init",
  };
};

const storePoolsPM = (dataUsage) => {
  return {
    type: "admin/storePoolsPM",
    payload: dataUsage,
  };
};

export const getDataUsagePPM = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/poolsPM?code=${key}`);
    dispatch(storePoolsPM(response.data));
  };
};

const storePoolPD = (dataUsage) => {
  return {
    type: "admin/storePoolPD",
    payload: dataUsage,
  };
};
export const getDataUsagePPD = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/poolsPD?code=${key}&&id=${id}`);
    dispatch(storePoolPD(response.data));
  };
};

const storePoolDetails = (details) => {
  return {
    type: "admin/storePoolDetails",
    payload: details,
  };
};
export const getPoolDetails = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/poolDetails?code=${key}&&id=${id}`);
    dispatch(storePoolDetails(response.data));
  };
};

const storePoolTopUsers = (topUsers) => {
  return {
    type: "admin/storePoolTopUsers",
    payload: topUsers,
  };
};
export const getPoolTopUsers = (id) => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/poolTopUsers?code=${key}&&id=${id}`);
    dispatch(storePoolTopUsers(response.data));
  };
};

const poolDekaDayCheck = () => {
  return async (dispatch, getState) => {
    axios.get(`/api/pools10DaysCheck?code=${key}`);
  };
};
export const updatePoolAlerts = (id, data) => {
  return async (dispatch, getState) => {
    dispatch(storePoolDetails({}));
    const res1 = await axios.post(
      `/api/updatePoolAlerts?code=${key}&&id=${id}`,
      {
        data,
      }
    );
    let res2 = await axios.get(`/api/poolDetails?code=${key}&&id=${id}`);
    dispatch(storePoolDetails(res2.data));
    if (res1.data[0] === 1) {
      dispatch(poolDekaDayCheck());
    }
  };
};

export const storeAntennaDetails = (details) => {
  return {
    type: "admin/storeAntennaDetails",
    payload: details,
  };
};
export const getAntennaDetails = (tag) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/signalAntennaDetails?code=${key}&&id=${tag}`
    );
    dispatch(storeAntennaDetails(response.data[0]));
  };
};

const storeModems = (all, unsupported) => {
  return {
    type: "admin/storeAllModems",
    payload: {
      all: all,
      unsupported: unsupported,
    },
  };
};
export const getModemsTags = () => {
  return async (dispatch, getState) => {
    let allModems = await axios.get(`/api/signalHardware?code=${key}`);
    let unsupported = await axios.get(
      `/api/signalUnsupportedHardware?code=${key}`
    );
    dispatch(storeModems(allModems.data, unsupported.data));
  };
};

export const setSignalDownload = (value) => {
  return {
    type: "admin/setSignalDownload",
    payload: value,
  };
};
export const setSignalUpload = (value) => {
  return {
    type: "admin/setSignalUpload",
    payload: value,
  };
};
export const storeSignalData = (value) => {
  return {
    type: "admin/setSignalData",
    payload: value,
  };
};
const updateTheBeep = (value) => {
  return {
    type: "admin/updateTheBeep",
    payload: value,
  };
};
const checkTheBeep = (newBeep) => {
  return async (dispatch, getState) => {
    if (newBeep !== getState().admin.signal.lastBeep) {
      dispatch(updateTheBeep(newBeep));
    }
  };
};
export const getSignal = (tag) => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/signalGetInfluxData?code=${key}&&tag=${tag}`
    );
    dispatch(checkTheBeep(response.data.time));
    dispatch(storeSignalData(response.data));
  };
};

const storeTerminaldevices = (value) => {
  return {
    type: "admin/storeTerminaldevices",
    payload: value,
  };
};
export const getTerminalDevices = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/terminalGetDevices?code=${key}`);
    dispatch(storeTerminaldevices(response.data));
  };
};

export const getAuth = () => {
  return async (dispatch, getState) => {
    let testRes = await axios.post(
      "https://hub.geuzenet.nl/api/auth",
      {
        api_key: "S/KaG+wkIGz85Ps5UgtuuErZCwDXrWhWCy8HOgVogB4=",
        // api_key: "S/KaG+wkIGz85Ps5Ug",
      },
      {
        withCredentials: true,
        headers: {
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/plain",
        },
      }
    );
    console.log(testRes);
    console.log(document.cookie);
  };
};
const storeTerminalSessionID = (value) => {
  return {
    type: "admin/storeTerminalSessionID",
    payload: value,
  };
};
export const getAuth2 = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/terminalAuth?code=${key}`);
    dispatch(storeTerminalSessionID(response.data));
  };
};

export const clearTerminalSessionID = () => {
  return {
    type: "admin/clearTerminalSessionID",
  };
};
const storeTerminalSupportedDevices = (value) => {
  return {
    type: "admin/storeTerminalSupportedDevices",
    payload: value,
  };
};
export const getTerminalSupportedDevices = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(
      `/api/terminalGetSupportedDevices?code=${key}`
    );
    dispatch(storeTerminalSupportedDevices(response.data));
  };
};
