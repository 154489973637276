import "../../css/adminSignalTest.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import closeIcon from "../../close.png";
import AntennaSignalComp from "../../components/antennaSignalCompV2";
import { useNavigate } from "react-router-dom";
import logo from "../../geuzenetlog.png";
import {
  setCategories,
  getAppUser,
  setSignalButtonState,
  setSignalAntennaTag,
  setSignalTagInput,
  reduxInit,
} from "../../store/actions/navigation";
import {
  selectNavigation,
  selectAppUser,
  selectButtonState,
  selectAntennaTag,
  selectTagInput,
} from "../../store/selectors/navigation";
import {
  storeAntennaDetails,
  getAntennaDetails,
  getModemsTags,
  setSignalDownload,
  setSignalUpload,
  storeSignalData,
} from "../../store/actions/admin";

import {
  selectAntennaDetails,
  selectAllModems,
  selectOnlineModems,
  selectUnsupported,
} from "../../store/selectors/admin";

const validUser = (user) =>
  user
    ? !(
        !user.userRoles.includes("user") &&
        !user.userRoles.includes("admin") &&
        !user.userRoles.includes("inst")
      )
    : false;
function AdminSubAdmin1() {
  useEffect(() => {
    dispatch(reduxInit()); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const appUser = useSelector(selectAppUser);
  const navigation = useSelector(selectNavigation);
  const modemTags = useSelector(selectAllModems);
  const onlineModems = useSelector(selectOnlineModems);
  const unsupportedModems = useSelector(selectUnsupported);
  const details = useSelector(selectAntennaDetails);
  const buttonState = useSelector(selectButtonState);

  const antennaTag = useSelector(selectAntennaTag);
  const tagTextInput = useSelector(selectTagInput);
  const navigate = useNavigate();
  let { tag } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (tag) {
      dispatch(setSignalButtonState(true));
      dispatch(setSignalAntennaTag(tag));
      dispatch(setSignalTagInput(tag));
      navigate(`/SupportSignal test`);
    }
  }, [dispatch, tag, navigate]);
  // let timer = null;
  // const handleMouseMove = () => {
  //   if (timer) clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     dispatch(storeAntennaDetails({}));
  //     // navigate(`/AdminSignal test`);
  //     dispatch(setSignalDownload(null));
  //     dispatch(setSignalUpload(null));
  //     dispatch(setSignalButtonState(false));
  //     dispatch(setSignalAntennaTag(""));
  //   }, 1 * 60 * 1000);
  // };
  // useEffect(() => {
  //   window.addEventListener("mousemove", handleMouseMove);
  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     if (timer) clearTimeout(timer);
  //   };
  // }, []);
  useEffect(() => {
    const setMenu = () => {
      if (!validUser(appUser)) {
        dispatch(setCategories("", "", ""));
      } else {
        dispatch(setCategories("Support", "Signal test", "details"));
      }
    };
    setMenu();
  }, [dispatch, appUser]);
  useEffect(() => {
    dispatch(getAppUser());
    const getItems = () => {
      if (validUser(appUser)) {
        if (modemTags.length === 0) {
          dispatch(getModemsTags());
        } else if (
          antennaTag.length > 0 &&
          modemTags.includes(antennaTag)
          //  &&
          // onlineModems.includes(antennaTag)
        ) {
          setAntenna({ status: "waiting" });
          dispatch(getAntennaDetails(antennaTag));
        } else if (antennaTag.length > 0 && !modemTags.includes(antennaTag)) {
          setAntenna({ status: "not found" });
        } else if (
          antennaTag.length > 0 &&
          unsupportedModems.includes(antennaTag)
        ) {
          setAntenna({ status: "Modem not supported" });
        } else if (
          antennaTag.length > 0 &&
          !onlineModems.includes(antennaTag)
        ) {
          setAntenna({ status: "SSH tunnel not available" });
        }
      }
    };
    getItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    modemTags,
    antennaTag,
    navigation.menuSub,
    navigation.menuMain,
  ]);

  const [antenna, setAntenna] = useState({ status: null });
  const handleEnter = (e) => {
    if (e.target.className === "tagInputField" && e.key === "Enter") {
      if (tagTextInput.slice(0, 3) === "GZN" && tagTextInput.length === 8) {
        dispatch(setSignalAntennaTag(tagTextInput));
      }
    }
  };
  if (Object.keys(details).length > 0 && antenna.status !== "done") {
    setAntenna({ status: "done" });
    dispatch(setSignalButtonState(true));
  }
  if (!validUser(appUser)) {
    return <></>;
  } else {
    return (
      <>
        {modemTags.length > 0 ? (
          modemTags.length > 0 && antennaTag === "" ? (
            <div className="inputArea">
              <div className="infoLine">
                <div className="infoLineKey">{"Antenna tag: "}</div>
                <div className="infoLineValue">
                  <input
                    className="tagInputField"
                    value={tagTextInput}
                    onKeyDown={handleEnter}
                    onChange={(e) => {
                      dispatch(
                        setSignalTagInput(
                          e.target.value.toUpperCase().slice(0, 8)
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <img className="landingLogo ll" src={logo} alt="company logo" />
        )}
        {antennaTag !== "" ? (
          <div className="modemInfoArea">
            <div className="infoLine">
              <div className="infoLineKey">
                <img
                  src={closeIcon}
                  alt={"close"}
                  onClick={(e) => {
                    dispatch(storeAntennaDetails({}));
                    // navigate(`/AdminSignal test`);
                    dispatch(setSignalDownload(null));
                    dispatch(setSignalUpload(null));
                    dispatch(setSignalButtonState(false));
                    dispatch(setSignalAntennaTag(""));
                    setTimeout(() => {
                      dispatch(storeSignalData({}));
                    }, 500);
                    document.close();
                  }}
                />
                {antennaTag}:
              </div>
              <div
                className="infoLineValue"
                style={
                  Object.keys(details).length > 0
                    ? { display: "flex", textAlign: "left" }
                    : { display: "flex" }
                }
              >
                {Object.keys(details).length > 0
                  ? details.location
                  : antenna.status}
              </div>
            </div>
            <div className="infoLine">
              <div className="infoLineKey">Model:</div>
              <div
                className="infoLineValue"
                style={
                  Object.keys(details).length > 0
                    ? { display: "flex", textAlign: "left" }
                    : { display: "flex" }
                }
              >
                {Object.keys(details).length > 0
                  ? details.modelDesc
                  : antenna.status}
              </div>
            </div>
            {details.tag_id &&
            buttonState &&
            ![25, 26].includes(details.model_id) ? (
              <>
                <AntennaSignalComp
                  modemTag={antennaTag}
                  buttonState={buttonState}
                />
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default AdminSubAdmin1;
