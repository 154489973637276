import axios from "axios";
const key = process.env.REACT_APP_API_KEY;

const storeAssetsData = (assets) => {
  return {
    type: "inventoryCheck/storeData",
    payload: assets,
  };
};

export const getAssetsData = () => {
  return async (dispatch, getState) => {
    let response = await axios.get(`/api/inventoryCheck?code=${key}`);
    if (response.data.length === 0) {
      dispatch(nullInventoryCheck());
    } else {
      dispatch(storeAssetsData(response.data));
    }
  };
};

export const nullInventoryCheck = () => {
  return { type: "inventoryCheck/setToNull" };
};
