import "../css/navbar.css";
import logo from "../geuzenet_logo_x1.png";
import { useMediaQuery } from "react-responsive";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAppUser, selectNavigation } from "../store/selectors/navigation";
import { useLocation } from "react-router-dom";
import Hamburger from "./Hamburger";
import { setCategories, getAppUser } from "../store/actions/navigation";

function Navbar() {
  const navbarRef = useRef();
  const appUser = useSelector(selectAppUser);
  const navigation = useSelector(selectNavigation);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [category, setCategory] = useState("");
  const isBigScreen = useMediaQuery({ query: "(min-width: 1220px)" });
  const loc = useLocation().pathname;

  if (navigation.menuMain && navigation.menuSub) {
    document.title = `Geuzenet > ${navigation.menuMain} > ${navigation.menuSub}`;
  } else {
    document.title = `Geuzenet`;
  }
  window.onclick = function (e) {
    if (
      ![
        "hamDiv",
        "burger burger1",
        "burger burger2",
        "burger burger3",
        "navbarLink",
        "navbarLinkActive",
      ].includes(e.target.className)
    ) {
      if (hamburgerOpen) {
        setHamburgerOpen(false);
      }
      setCategory("");
    }
  };
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };
  const changeCategory = (cat) => {
    if (category === cat) {
      setCategory("");
    } else {
      setCategory(cat);
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppUser());
    dispatch(setCategories("dupa"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const subSelect = (main, sub) => {
    toggleHamburger();
    setCategory("");

    navigate(`/${main}${sub}`);
  };
  const navButton = (main, sub) => {
    return (
      <button
        key={sub}
        className={
          navigation.menuSub === sub ? "submenuActive" : "submenuUnactive"
        }
        value={sub}
        onClick={(e) => {
          subSelect(main, sub);
        }}
      >
        {`${sub}`}
      </button>
    );
  };
  let categoriesArray = [];

  const categoryConst = (cat, subArr) => {
    return {
      category: cat,
      height: `${subArr.length * 20 + 5}px`,
      subCategories: subArr,
    };
  };
  let invoicingCat = categoryConst("Invoicing", ["testPage"]);
  let supportCat = categoryConst("Support", [
    "Tickets",
    "Signal test",
    "Terminal",
  ]);
  let instSupportCat = categoryConst("Support", ["Signal test"]);
  let firecellSupportCat = categoryConst("Support", ["Terminal"]);
  let servicesCat = categoryConst("Services", [
    "Overview",
    "Contacts",
    "Koffers",
    "Overuse",
  ]);
  let ordersCat = categoryConst("Orders", ["Overview", "Dirty orders"]);
  let assetsCat = categoryConst("Assets", [
    "Devices",
    "Add devices",
    "Update devices",
    "Sims",
    "Add sims",
    "Update sims",
    "Inventory check",
  ]);
  let adminAdminCat = categoryConst("Admin", [
    "Logs tables",
    "Pools usage",
    "PSQL edit",
  ]);
  if (appUser.userRoles) {
    if (appUser.userRoles.includes("admin")) {
      categoriesArray = [
        invoicingCat,
        supportCat,
        servicesCat,
        ordersCat,
        assetsCat,
        adminAdminCat,
      ];
    }
    if (appUser.userRoles.includes("user")) {
      categoriesArray = [supportCat, servicesCat, ordersCat, assetsCat];
    }
    if (appUser.userRoles.includes("inst")) {
      categoriesArray = [instSupportCat];
    }
    if (appUser.userRoles.includes("firecell")) {
      categoriesArray = [firecellSupportCat];
    }
  }
  return appUser ? (
    <div>
      <div className="Navbar" ref={navbarRef}>
        <img
          className="navLogo"
          src={logo}
          alt="company logo"
          onClick={() => {
            dispatch(setCategories("", "", ""));
            navigate("/");
          }}
        />
        {/* dev: */}
        {/* <span
          style={{
            alignSelf: "end",
            marginLeft: "-32px",
            marginBottom: "-3px",
            filter: "invert(1)",
          }}
        >
          DEV
        </span> */}
        <div
          className={`${
            isBigScreen
              ? "navLinks horizontal"
              : `${hamburgerOpen ? "navLinks open" : "navLinks"}`
          }`}
        >
          {categoriesArray.length > 0
            ? categoriesArray.map((cat) => (
                <div
                  key={cat.category}
                  className={`${
                    category === cat.category &&
                    appUser.userRoles.includes(
                      "user",
                      "admin",
                      "inst",
                      "firecell"
                    ) &&
                    !isBigScreen
                      ? "navCategoryExpanded"
                      : "navCategory"
                  }`}
                >
                  <div
                    className={
                      navigation.menuMain === cat.category
                        ? "navbarLinkActive"
                        : "navbarLink"
                    }
                    onClick={(e) => {
                      changeCategory(cat.category);
                    }}
                  >
                    {cat.category}
                  </div>
                  <div
                    className={`${
                      category === cat.category ? "submenu expanded" : "submenu"
                    }`}
                    style={{
                      width: "140px",
                      height: `${category === cat.category ? cat.height : "0"}`,
                    }}
                  >
                    {cat.subCategories.map((sub) =>
                      navButton(cat.category, sub)
                    )}
                  </div>
                </div>
              ))
            : ""}
          {!appUser.userId ? (
            <a
              className="navbarLink"
              href="/login"
              onClick={(e) => {
                changeCategory("");
              }}
            >
              Log in
            </a>
          ) : (
            <a
              className="navbarLink"
              href="/logout"
              onClick={(e) => {
                changeCategory("");
              }}
            >
              Log out
            </a>
          )}
        </div>
        <div
          className="hamburger"
          onClick={(e) => {
            toggleHamburger(e);
          }}
        >
          <Hamburger isOpen={hamburgerOpen} />
        </div>
      </div>
      {isBigScreen ? (
        <style jsx="true">
          {`
            .Navbar {
              height: 50px;
            }

            .Navbar .navLinks {
              flex-wrap: wrap;
              float: right;
              padding: 0px;
              overflow: hidden;
              animation: fadeIn 1s;
            }
            .Navbar .navLinks .navbarLink {
              list-style-type: none;
            }
            .hamburger {
              display: none;
            }
            .submenu {
              margin-left: auto;
              margin-right: auto;
              border: 0px solid black;
              border-top: none;
            }
            .expanded {
              height: 50px;
              border: 1px solid black;
              border-top: none;
              border-radius: 0 0 10px 10px;
            }
            .navbarLink,
            .navbarLinkActive {
              margin-bottom: 1vh;
            }
          `}
        </style>
      ) : (
        <style jsx="true">
          {`
            .hamburger {
              display: fixed;
              padding: 10px 0;
              margin-left: auto;
              margin-right: 5px;
              margin-bottom: 2px;
              width: 140px;
            }
            .Navbar {
              height: 50px;
            }
            .Navbar .navLinks {
              background: rgba(220, 220, 220, 0.9);
              display: inline-grid;
              position: fixed;
              right: 5px;
            }
            .expanded {
              background: rgba(240, 240, 240, 0.9);
            }
            .navLinks {
              width: 140px;
              flex-direction: column;
              overflow: hidden;
              transition: 1s;
              height: 0;

              z-index: 6;
              right: 5.5vw;
              border-radius: 10px;
              padding-top: 50px;
              box-shadow: -5px -5px 10px 0px rgb(0 0 0 / 20%);
              border: 1px black solid;
            }
            .open {
              height: auto;
              z-index: 4;
            }
            .navbarLink,
            .navbarLinkActive {
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 0px;
            }
          `}
        </style>
      )}
      <p className="pageTitle">
        {appUser.userRoles
          ? (!appUser.userRoles.includes("user") &&
              !appUser.userRoles.includes("admin") &&
              !appUser.userRoles.includes("firecell") &&
              !appUser.userRoles.includes("inst")) ||
            loc === "/" ||
            !navigation.menuMain ||
            !navigation.menuSub
            ? ``
            : `${navigation.menuMain} > ${navigation.menuSub}`
          : ``}
      </p>
    </div>
  ) : (
    ""
  );
}

export default Navbar;
